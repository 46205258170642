<template>
  <div>
    <header class="main-header">
      <div class="row row-page-top">
        <h1 class="poster-rail--title section-title">Mi contenido</h1>
      </div>
    </header>

    <main
      class="wrapper container-fluid my-content"
      :class="{ empty: isEmpty }"
      role="main"
    >
      
      <EmptyContent v-if="isEmpty" />

      <template v-if="!isEmpty">
        <Slider
          v-if="activos.length > 0"
          :data="activos"
          :title="'Continuar viendo'"
        >
          <template slot-scope="itemslider">
            <CardVOD
              v-for="(item, index) of itemslider.data"
              :key="index + item.idAsset"
              :data="item"
              componentOrigin="VOD"
              :sliderId="'slider-continuar-viendo'"
              :sliderTitle="'Continuar viendo'"
              :cardId="'card-' + item.idAsset"
            />
          </template>
        </Slider>

        <Slider
          v-if="grabacionesActuales.length > 0"
          :data="grabacionesActuales"
          :title="'Grabaciones disponibles'"
        >
          <template slot-scope="itemslider">
            <template v-for="(item, index) of itemslider.data">
              <CardChapter
                v-if="item.channel.listed"
                :key="item.idEvent"
                :data="item"
                @info="onInfo(item)"
                @delete="onDelete(item)"
                @play="onPlay(item)"
                :contentType="RECORD"
                componentOrigin="EPG"
                :sliderId="'slider-grabaciones-disponibles'"
                :sliderTitle="'Grabaciones disponibles'"
                :cardId="'card-' + item.idAsset"
              />
              <CardChapter
                v-else
                :key="item.idEvent"
                :data="item"
                @info="onInfo(item)"
                @delete="onDelete(item)"
                componentOrigin="EPG"
                :sliderId="'slider-grabaciones-disponibles'"
                :sliderTitle="'Grabaciones disponibles'"
                :cardId="'card-' + index"
              />
            </template>
          </template>
        </Slider>

        <Slider
          v-if="grabacionesPlanificadas.length > 0"
          :data="grabacionesPlanificadas"
          :title="'Grabaciones programadas'"
        >
          <template slot-scope="itemslider">
            <CardChapter
              v-for="(item, index) of itemslider.data"
              :key="item.idEvent"
              :data="item"
              @info="onInfo(item)"
              @delete="onDelete(item)"
              componentOrigin="EPG"
              :contentType="RECORD"
              :sliderId="'slider-grabaciones-programadas'"
              :sliderTitle="'Grabaciones programadas'"
              :cardId="'card-' + index"
            />
          </template>
        </Slider>

        <Slider
          v-if="peliculasFavoritas.length > 0"
          :data="peliculasFavoritas"
          :title="'Mis películas favoritas'"
        >
          <template slot-scope="itemslider">
            <CardVOD
              v-for="(item, index) of itemslider.data"
              :key="item.idAsset"
              :data="item"
              componentOrigin="VOD"
              :sliderId="'slider-peliculas-favoritas'"
              :sliderTitle="'Mis películas favoritas'"
              :cardId="'card-' + item.idAsset"
            />
          </template>
        </Slider>

        <Slider
          v-if="seriesFavoritas.length > 0"
          :data="seriesFavoritas"
          :title="'Mis series favoritas'"
        >
          <template slot-scope="itemslider">
            <CardVOD
              v-for="(item, index) of itemslider.data"
              :key="index + item.idSerie"
              :data="item"
              :showGroupInfo="true"
              componentOrigin="VOD"
              :sliderId="'slider-series-favoritas'"
              :sliderTitle="'Mis series favoritas'"
              :cardId="'card-' + index"
            />
          </template>
        </Slider>
      </template>
    </main>

    <Loader v-if="showFullLoader" />
  </div>
</template>

<script>
const ADULT_CHANNELS_ID = [94, 95, 96, 1146];

export default {
  name: "Records",

  components: {
    Slider: () => import("@/components/Slider.vue"),
    Loader: () => import("@/components/Loader.vue"),
    CardVOD: () => import("@/components/Card-VOD.vue"),
    CardChapter: () => import("@/components/Card-chapter.vue"),
    EmptyContent: () => import("@/components/EmptyContent.vue"),
  },

  inject: ["modalConfirmShow"],

  data() {
    return {
      showFullLoader: true,
      isEmpty: true,
      isNull: true,
      activos: [],
      grabacionesActuales: [],
      grabacionesPlanificadas: [],
      peliculasFavoritas: [],
      seriesFavoritas: [],
      blockByParentalControl: false,
      RECORD: "RECORD",
    };
  },

  created() {
    const self = this;

    self.$bus.$on("user:quota-change", self.onQuotaChange);

    self.showLoader(true);

    telecentro.tplay.core.helpers.join(
      telecentro.tplay.core.npvr.verGrabaciones,
      telecentro.tplay.core.favoritos.traerFavoritosPorTipo,
      telecentro.tplay.core.vod.obtenerActivos
    )(function (grabaciones, favoritos, activos) {
      const grabacionesActuales =
        telecentro.tplay.core.seriesEpg.agruparProgramasPorSerieYTemporada(
          grabaciones.actuales
        );
      const grabacionesPlanificadas =
        telecentro.tplay.core.seriesEpg.agruparProgramasPorSerieYTemporada(
          grabaciones.planificadas
        );

      self.grabacionesActuales = self.filterAdultChannels(grabacionesActuales);
      self.grabacionesPlanificadas = self.filterAdultChannels(
        grabacionesPlanificadas
      );

      self.peliculasFavoritas = favoritos.movies;
      self.seriesFavoritas = favoritos.seriesAgrupadas;
      // console.log('series favoritas', self.seriesFavoritas);

      self.activos = activos.filter((item) => item.status === undefined);

      self.isEmpty =
        self.grabacionesActuales?.length === 0 &&
        self.grabacionesPlanificadas?.length === 0 &&
        self.peliculasFavoritas?.length === 0 &&
        self.seriesFavoritas?.length === 0 &&
        self.activos?.length === 0; 

      self.isNull =
        self.grabacionesActuales === null ||
        (self.grabacionesActuales === undefined &&
          self.grabacionesPlanificadas === null) ||
        (self.grabacionesPlanificadas === undefined &&
          self.peliculasFavoritas === null) ||
        (self.peliculasFavoritas === undefined &&
          self.seriesFavoritas === null) ||
        (self.seriesFavoritas === undefined && self.activos === null) ||
        self.activos === undefined;

      self.errorLoadAndReturnHome(self.isNull);
      self.showLoader(false);
    });
  },

  mounted() {
    document.body.classList.add("no-filters");
  },

  beforeDestroy() {
    this.$bus.$off("user:quota-change", this.onQuotaChange);
    document.body.classList.remove("no-filters");
  },

  methods: {
    errorLoadAndReturnHome(isNull) {
      if (isNull) {
        this.$bus.$emit(
          "show-toast",
          "info",
          "Lo sentimos el contenido esta tardando demaciado"
        );
        setTimeout(() => {
          this.$router.push({ path: "/inicio" });
        }, 2000);
      }
    },

    showLoader(show) {
      this.showFullLoader = show;
      document.documentElement.style.cursor = show ? "wait" : "default";
    },

    traerGrabaciones(onFinish) {
      const self = this;

      telecentro.tplay.core.npvr.verGrabaciones(function (grabaciones) {
        const grabacionesActuales =
          telecentro.tplay.core.seriesEpg.agruparProgramasPorSerieYTemporada(
            grabaciones.actuales
          );
        const grabacionesPlanificadas =
          telecentro.tplay.core.seriesEpg.agruparProgramasPorSerieYTemporada(
            grabaciones.planificadas
          );

        self.grabacionesActuales =
          self.filterAdultChannels(grabacionesActuales);
        self.grabacionesPlanificadas = self.filterAdultChannels(
          grabacionesPlanificadas
        );

        onFinish();
      });
    },

    onInfo(grabacion) {
      //Hay 4 casos, según si se trata de VOD o EPG, y si es un programa individual o una serie.
      //La CardVod maneja directamente los eventos (lo cual no está muy bueno, porque no permite control externo), así que en verdad sólo se llama para EPG este método.
      //VOD 1 episodio: ModalGroupVod
      //VOD n episodios: ModalGroupVod
      //EPG 1 episodio: ModalTv
      //EPG n episodios: ModalGroupTv

      this.blockByParentalControl =
        telecentro.tplay.core.biz.parentalControl.blockAsset(grabacion);

      if (this.blockByParentalControl) {
        this.$bus.$emit("modal-pin:show", function () {
          if (grabacion.seasons) {
            //n episodios
            this.$bus.$emit("modal-group-tv:show", { data: grabacion });
          } else {
            //1 episodio
            this.$bus.$emit("modal-tv:show", { data: grabacion });
          }
        });
      } else {
        if (grabacion.seasons) {
          //n episodios
          this.$bus.$emit("modal-group-tv:show", { data: grabacion });
        } else {
          //1 episodio
          this.$bus.$emit("modal-tv:show", { data: grabacion });
        }
      }
    },

    onDelete(grabacion) {
      const self = this;

      this.modalConfirmShow({
        type: "confirm",
        classType: "danger",
        title: "Confirmá esta acción",
        acceptText:
          `Sí, ` + (grabacion.status != "Recorded" ? "Cancelar" : "Eliminar"),
        cancelText: "No",
        content:
          `<p>Vas a ` +
          (grabacion.status != "Recorded" ? "cancelar" : "eliminar") +
          ` la grabación de "` +
          grabacion.title +
          `". ¿Estás seguro?</p>`,

        // type: 'confirm',
        // classType: 'danger',
        // title: 'Confirmá esta acción',
        // acceptText: 'Sí, Eliminar',
        // cancelText: 'No',
        // content: '<p>Vas a eliminar la grabación de "' + grabacion.title + '". ¿Estás seguro?</p>',

        confirm: function (ok) {
          if (ok) {
            self.showLoader(true);
            //Como las grabaciones están agrupadas, tengo que borrar todas las del grupo.
            //Quiero enterarme cuando terminen todas, así que creo una función para el
            //borrado de cada grabación, y después hago un join.

            const accionesBorrado = [];
            const results = [];

            const borrarGrabacion = function (capitulo, done) {
              telecentro.tplay.core.npvr.borrarGrabacion(
                capitulo.idRecord,
                function (ret) {
                  if (ret.status) capitulo.grabado(false);

                  results.push({ capitulo: capitulo, retorno: ret });
                  done();
                }
              );
            };

            if (grabacion.seasons) {
              for (let i = 0; i < grabacion.seasons.length; i++) {
                let season = grabacion.seasons[i];
                for (let j = 0; j < season.capitulos.length; j++) {
                  accionesBorrado.push(
                    __.partial(borrarGrabacion, season.capitulos[j])
                  );
                }
              }
            } else {
              accionesBorrado.push(__.partial(borrarGrabacion, grabacion));
            }

            //Ejecuto todas las acciones de borrado y espero a que terminen todas
            telecentro.tplay.core.helpers.join.apply(
              null,
              accionesBorrado
            )(function () {
              //Se terminaron de ejecutar todas las acciones de borrado

              let totalResult = 0;
              let errCount = 0;
              let errorRow = 0;

              for (let idx = 0; idx < results.length; idx++) {
                const result = results[idx].retorno;

                if (!result.status) {
                  errCount++;
                  errorRow = idx;
                }

                totalResult++;
              }

              if (errCount == 0) {
                if (totalResult.length > 1) {
                  self.$bus.$emit(
                    "show-toast",
                    "success",
                    "Se eliminaron las grabaciones indicadas."
                  );
                } else {
                  self.$bus.$emit(
                    "show-toast",
                    "success",
                    "Se eliminó la grabación indicada."
                  );
                }
              } else if (errCount == 1) {
                self.$bus.$emit(
                  "show-toast",
                  "error",
                  'No se pudo eliminar la grabación de "' +
                    results[errorRow].capitulo.title +
                    '".'
                );
                self.showLoader(false);
              } else {
                self.$bus.$emit(
                  "show-toast",
                  "error",
                  "No se pudieron eliminar algunas grabaciones."
                );
                self.showLoader(false);
              }

              self.$bus.$emit("user:quota-change");
            });
          }
        },
      });
    },

    onPlay(item) {
      //OJO: para TV se entra por acá, pero para VOD no, porque el play lo maneja la card

      const opt = {
        rail: {
          show: false,
        },
      };

      if (item.seasons) {
        this.$bus.$emit("player:set-source", item.seasons[0].capitulos[0], opt);
      } else {
        this.$bus.$emit("player:set-source", item, opt);
      }
    },

    onQuotaChange() {
      const self = this;
      self.showLoader(true);
      self.traerGrabaciones(function () {
        self.showLoader(false);
      });
    },

    filterAdultChannels(chList) {
      let filteredList = chList;
      ADULT_CHANNELS_ID.map((id) => {
        filteredList = filteredList.filter((ch) => ch.idChannel !== id);
      });
      return filteredList;
    },
  },
};
</script>
